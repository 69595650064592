<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="goal_score.length == 0 && tables_stats.length == 0">
        {{ $t("No data found") }}
    </div>
    <div class="ftb-md-th" v-else>
        <div class="ftb_table_section ftb_history_section">
            <div class="ftb_player_history_table">
                <div class="ftb_lineup_mobi_tabs">
                  <ul>
                    <li>
                      <a :class="(activeTeamMobile == 1) ? 'selected': ''" @click="activeTeamMobile = 1" href="javascript:;">
                        {{ $getSafe( () => $filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors,true).name) }}
                      </a>
                    </li>
                    <li>
                      <a :class="(activeTeamMobile == 2) ? 'selected': ''" @click="activeTeamMobile = 2" href="javascript:;">
                        {{ $getSafe( () => $filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors,false).name) }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="ftb_player_history_title_row">
                    <div class="ftb_player_history_col">
                        <h2 class="overview_main_title"></h2>
                    </div>
                    <div class="ftb_player_history_col">
                        <h2 class="overview_main_title">
                            {{ $getSafe( () => $filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors,true).name) }}</h2>
                    </div>
                    <div class="ftb_player_history_col">
                        <h2 class="overview_main_title">
                            {{ $getSafe( () => $filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors,false).name) }}</h2>
                    </div>
                </div>
                <div class="h2h-table" v-if="goal_score.length != 0"  :class="(activeTeamMobile == 1) ? 'team1_active' : 'team2_active'">
                    <div class="ftb_player_history_subtitle_row">
                        <div class="ftb_player_history_col"></div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li><strong>{{$t('football.match-detail.Home')}}</strong></li>
                                <li><strong>{{$t('football.match-detail.Away')}}</strong></li>
                                <li><strong>{{$t('football.match-detail.Overall')}}</strong></li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li><strong>{{$t('football.match-detail.Home')}}</strong></li>
                                <li><strong>{{$t('football.match-detail.Away')}}</strong></li>
                                <li><strong>{{$t('football.match-detail.Overall')}}</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li class="sabc_hishd"><span>{{ $t('football.match-detail.Goal For')}}</span></li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul v-if="goal_score.home_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_for').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_for').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_for').total_value)}}</li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul v-if="goal_score.away_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_for').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_for').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_for').total_value)}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li class="sabc_hishd"><span>{{ $t('football.match-detail.AVG Goal For')}}</span></li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul v-if="goal_score.home_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'average_goal_for').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'average_goal_for').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'average_goal_for').total_value)}}</li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul v-if="goal_score.away_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'average_goal_for').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'average_goal_for').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'average_goal_for').total_value)}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li class="sabc_hishd"><span>{{ $t('football.match-detail.Goal Against')}}</span></li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col" v-if="goal_score.home_goal">
                            <ul v-if="goal_score.home_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_against').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_against').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.home_goal,'goal_against').total_value)}}</li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col" v-if="goal_score.away_goal">
                            <ul v-if="goal_score.home_goal.length">
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_against').home_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_against').away_value)}}</li>
                                <li>{{$getSafe( () => findGoalType(goal_score.away_goal,'goal_against').total_value)}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="tables_stats.length != 0"  :class="(activeTeamMobile == 1) ? 'team1_active' : 'team2_active'">
                    <div class="ftb_player_history_subtitle_row">
                        <div class="ftb_player_history_col">
                        </div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    <strong>Pd</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>W</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>D</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>L</strong>
                                </li>
                                <li>
                                    <strong>Pts</strong>
                                </li>
                                <li>
                                    <strong>{{ $t("Rank")}}</strong>
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    <strong>Pd</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>W</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>D</strong>
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    <strong>L</strong>
                                </li>
                                <li>
                                    <strong>Pts</strong>
                                </li>
                                <li>
                                    <strong>{{ $t("Rank")}}</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    <span>{{ $t("football.match-detail.Last 8 Overall") }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul >
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_eight_home').place)}}
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul >
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,"last_eight_away").place)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    <span>{{ $t('football.match-detail.Last 5 Home') }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_home').place)}}
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_home').place)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="ftb_player_history_data_row">
                        <div class="ftb_player_history_col">
                            <ul>
                                <li>
                                    <span>{{ $t('football.match-detail.Last 5 Away')}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul >
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.home_team,'last_five_home_at_away').place)}}
                                </li>
                            </ul>
                        </div>
                        <div class="ftb_player_history_col">
                            <ul >
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').played)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').won)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').draws)}}
                                </li>
                                <li class="ftb_history_col_hide_mobile">
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').lost)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').points)}}
                                </li>
                                <li>
                                    {{$getSafe(() => findTableStatType(tables_stats.away_team,'last_five_away_at_away').place)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="stscntr_comparison_hdtohd" v-if="h2h.length">
            <h2> Recent Head to Head Results </h2>
            <div class="ft_lc_list">
                <div class="ft_lc_row" v-for="match in h2h" :key="match.id">
                    <!--<td>PR 17</td>-->
                    <div class="ft_lc_time ft_lc_h2htime">
                      <span>
                        {{match.datetime | formatDate('DD-MMM-YYYY') }}
                      </span>
                    </div>
                    <div class="ft_lc_rslt">
                      <router-link :to="{ name: 'match_detail.overview', params: {slug: $slugify(match.tournament.name + ' ' + $getSafe(()=>$filterTeam(match.competitors, true).team.name) + ' vs ' + $getSafe(()=>$filterTeam(match.competitors, false).team.name)), date: $route.params.date, matchId: match.id} }">
                        <div class="ft_lc_name">
                          <span class="ft_lc_hm_tname">{{ $getSafe(() => $filterTeam( match.competitors, true).team.name) }}</span>
                          <span class="ft_lc_hm_tname_mbl">{{ $getSafe(() => $filterTeam( match.competitors, true).team.name) }}</span>
                          <img :src="$filterTeam(match.competitors, true).team.flag" :onerror="$teamDefaultLogo()" :alt="$getSafe(()=>$filterTeam(match.competitors, true).team.name)+' Team Logo'" class="ft_lc_hm_pic">
                        </div>
                        <div class="ft_lc_score">
                          <h6 class="ft_lc_score_ft">
                            {{$formatFootballScore(match.ft)}}
                          </h6>
                        </div>
                        <div class="ft_lc_name">
                          <img :src="$filterTeam(match.competitors, false).team.flag" :onerror="$teamDefaultLogo()" :alt="$getSafe(()=>$filterTeam(match.competitors, false).team.name)+' Team Logo'" class="ft_lc_awy_pic">
                          <span class="ft_lc_awy_tname_mbl">{{ $getSafe(() => $filterTeam(match.competitors, false).team.name) }}</span>
                          <span class="ft_lc_awy_tname">{{ $getSafe(() => $filterTeam(match.competitors, false).team.name) }}</span>
                        </div>
                      </router-link>
                    </div>
                    <div class="ft_lc_fav"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MatchDetailTeamhistory",
        data() {
            return {
                tables_stats: [],
                goal_score: [],
                h2h: [],
                activeTeamMobile: 1,
            };
        },
        mounted() {
            this.getHistory();
        },
        serverPrefetch() {
            return this.getHistory()
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$getSafe(()=>(this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name)),
                '%AWAY_TEAM%': this.$getSafe(()=>(this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name)),
                '%DATE%' : this.$route.params.date,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_history_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_history_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.football_match_history_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getHistory() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$FB_MATCH_HISTORY_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data) {
                            this.goal_score = response.data.goal_scores || [];
                            this.tables_stats = response.data.table_stats || [];
                            this.h2h = response.data.h2h || [];
                        }
                        this.is_data_ready = true;
                    })
                    .catch(error => console.log(error))
            },
            findGoalType(array, value) {
                let goal = array.filter((item) => {
                    if(item.name == value) {
                        return item;
                    }
                });
                return goal[0];
            },
            findTableStatType(array, value) {
                let stat = array.filter((item) => {
                    if(item.name == value) {
                        return item;
                    }
                });
                return stat[0];
            }
        }
    };
</script>
<style scoped>
.ftb-md-th{background: #ffffff;}
</style>
